import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { InputAdornment } from '@material-ui/core';
import { Person, Lock } from '@material-ui/icons';
import { black } from '@avangard/ui/colors';
import { TextField, Button } from '@avangard/ui/core';

import { TokenSession } from '@lib/token';
import { useNavigate, createRouteUrl } from '@lib/routing';
import { routes } from '@config/routes';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { RegisterStudentMutation } from '@modules/auth/graphql';
import { RegisterStudentRequest } from '@modules/auth/requests';
import { LocationFormGroup } from './location-form-group';

import type { RegisterStudentFormValues } from '@modules/auth/requests';
import type {
    RegisterStudentMutationType,
    RegisterStudentMutationVariables,
} from '@modules/types/graphql';
import { AgreeCheckbox } from '@modules/auth/atoms';

type SignupFormProps = {
    className?: string;
};

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    width: 100%;
    margin-top: 24px;
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    > * {
        width: 100%;
    }
    > h3 {
        color: ${black[80]};
        font-size: 2.8rem;
        font-weight: 500;
    }
`;

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 24px;
`;

const StyledForm = styled(Form)`
    width: 100%;

    > *:not(:last-child) {
        margin-bottom: 24px;
    }
`;

const StyledFormRowWithMargin = styled(FormStyled.FormRowWithMargin)`
    &:not(:last-of-type) {
        margin-bottom: 12px;
    }
`;

const personIcon = <Person style={{ fontSize: 16 }} />;
const lockIcon = <Lock style={{ fontSize: 16 }} />;

const SignupForm = (props: SignupFormProps): React.ReactElement => {
    const { t } = useTranslation(['common', 'errors', 'auth']);

    const navigate = useNavigate();

    const [signup] =
        useMutation<RegisterStudentMutationType, RegisterStudentMutationVariables>(
            RegisterStudentMutation,
        );

    return (
        <Root {...props}>
            <Header>
                <h3>{t('auth:blocks.titles.register')}</h3>
            </Header>

            <Main>
                <ExtendedFormik<RegisterStudentFormValues & { agree: boolean }>
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={yup.object({
                        fullName: yup.string().required(t('errors:validations.required') ?? ''),
                        region: yup.mixed().required(t('errors:validations.required') ?? ''),
                        location: yup.mixed().required(t('errors:validations.required') ?? ''),
                        phone: yup.string().required(t('errors:validations.required') ?? ''),

                        email: yup
                            .string()
                            .email()
                            .required(t('errors:login.required') ?? '')
                            .typeError(t('errors:validations.invalid_format') ?? ''),

                        password: yup
                            .string()
                            .min(8, t('errors:password.min') ?? '')
                            .required(t('errors:password.typing') ?? ''),

                        confirmPassword: yup
                            .string()
                            .min(8, t('errors:password.min') ?? '')
                            .oneOf(
                                [yup.ref('password'), null],
                                t('errors:password.must_equal') ?? '',
                            )
                            .required(t('errors:password.repeat') ?? ''),

                        agree: yup.bool().isTrue().required(),
                    })}
                    initialValues={{
                        fullName: '',
                        phone: '',
                        email: '',
                        password: '',
                        confirmPassword: '',
                        region: null,
                        location: null,
                        regionalCenter: null,
                        agree: false,
                    }}
                    onSubmit={async values => {
                        try {
                            const args = new RegisterStudentRequest(values);

                            const { data: signupData } = await signup({
                                variables: { args },
                            });

                            if (
                                signupData?.registerStudent?.accessToken &&
                                signupData?.registerStudent?.refreshToken
                            ) {
                                TokenSession.setCurrentSession(signupData?.registerStudent);

                                await navigate(routes.index.path);
                            }
                        } catch (e) {
                            throw e;
                        }
                    }}
                >
                    {formikProps => {
                        const { values, errors, handleChange, handleBlur, isSubmitting } =
                            formikProps;

                        const handleClickRedirectToLogin = (): void => {
                            const route = createRouteUrl(routes.login);

                            navigate(route, { state: { email: values.email } });
                        };

                        return (
                            <StyledForm>
                                <TextField
                                    fullWidth
                                    required
                                    id='fullName'
                                    label={t('common:form.labels.full_name')}
                                    placeholder={
                                        t('common:form.placeholder.typing_full_name') ?? ''
                                    }
                                    value={values.fullName}
                                    error={!!errors.fullName}
                                    helperText={errors.fullName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <LocationFormGroup />

                                <TextField
                                    fullWidth
                                    required
                                    id='phone'
                                    label={t('common:form.labels.phone')}
                                    placeholder={t('common:form.placeholder.phone') ?? ''}
                                    value={values.phone}
                                    error={!!errors.phone}
                                    helperText={errors.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <TextField
                                    fullWidth
                                    required
                                    id='email'
                                    type='email'
                                    label={t('common:form.labels.email')}
                                    placeholder={t('common:form.placeholder.email') ?? ''}
                                    value={values.email}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                {personIcon}
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    required
                                    id='password'
                                    type='password'
                                    label={t('common:form.labels.password')}
                                    placeholder={t('common:form.placeholder.password') ?? ''}
                                    value={values.password}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                {lockIcon}
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    required
                                    id='confirmPassword'
                                    type='password'
                                    label={t('common:form.labels.repeat_password')}
                                    placeholder={t('common:form.placeholder.password') ?? ''}
                                    value={values.confirmPassword}
                                    error={!!errors.confirmPassword}
                                    helperText={errors.confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                {lockIcon}
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <AgreeCheckbox
                                    name='agree'
                                    onChange={handleChange}
                                    hasError={!!errors.agree}
                                />

                                <StyledFormRowWithMargin>
                                    <Button fullWidth type='submit' loading={isSubmitting}>
                                        {t('common:actions.register')}
                                    </Button>
                                </StyledFormRowWithMargin>

                                <StyledFormRowWithMargin>
                                    <Button
                                        fullWidth
                                        variant='text'
                                        type='button'
                                        disabled={isSubmitting}
                                        onClick={handleClickRedirectToLogin}
                                    >
                                        {t('common:actions.login')}
                                    </Button>
                                </StyledFormRowWithMargin>
                            </StyledForm>
                        );
                    }}
                </ExtendedFormik>
            </Main>
        </Root>
    );
};

export { SignupForm };
